// Importamos hooks de react que usaremos para este componente
import { useState } from "react";
// Importamos componentes de la libreria de bootstrap para react
import { Tab, Tabs, Container, Row, Col, Card } from "react-bootstrap";

// ---------------------------------------------------------------------------------------

/* Importamos los componentes AutoTabForm y ManualTabForm 
para construir las dos pestañas de creacion de campañas */
import { AutoTabForm } from "./tabsNewCampaign/AutoTabForm";
import { ManualTabForm } from "./tabsNewCampaign/ManualTabForm";

//Iniciamos funcion para construir componente ModalNewCampaign
function ModalNewCampaign({closeModal, productId, twlCredentials, setCurrentPage, formDataManual, setFormDataManual, getCampaignsRegisters}){
  console.log('id producto en modal de nuevo: ', productId);
    const [activeTab, setActiveTab] = useState('automaticas');
    const [formData, setFormData] = useState({
      leadsField1: '',
      leadsField2: '',
      leadsField3: '',
      leadsField4: '',
      vendedoresField1: '',
      vendedoresField2: '',
      vendedoresField3: '',
      vendedoresField4: '',
    });
  
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Aquí puedes manejar la lógica de envío de datos, por ejemplo, enviar datos a un servidor.
      console.log('Datos enviados:', formData);
    };
  
    return (
      <Container>
        <Row>
          <Col>
            <Tabs activeKey={activeTab} onSelect={handleTabChange}>
              <Tab eventKey="automaticas" title="Automaticas">
                  <div className="container">
                      <Card style={{ width: '55rem' }}>
                          <Card.Body>
                            <AutoTabForm 
                              productId={productId} 
                              closeModal={closeModal}
                              getCampaignsRegisters={getCampaignsRegisters}
                              setCurrentPage={setCurrentPage}/>
                          </Card.Body>
                      </Card>
                  </div>
              </Tab>
              <Tab eventKey="manuales" title="Manuales">
              <div className="container">
                      <Card style={{ width: '55rem' }}>
                          <Card.Body>
                            <ManualTabForm 
                              productId={productId}
                              twlCredentials={twlCredentials}
                              formDataManual={formDataManual}
                              setFormDataManual={setFormDataManual}
                              closeModal={closeModal}
                              getCampaignsRegisters={getCampaignsRegisters}
                              setCurrentPage={setCurrentPage}
                            />
                          </Card.Body>
                      </Card>
                  </div>
              </Tab>
           
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
}

export {ModalNewCampaign};