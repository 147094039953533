// Importamos hooks de react que usaremos para este componente
import { useContext, useEffect, useState } from "react";
// Importamos componentes Modal de la libreria de bootstrap para react
import { Modal } from "react-bootstrap";
// Importamos libreria para manejo de lenguaje
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos el componente CardCampaigns
import { CardCampaigns } from "./aux-components/CardCampaigns";
// Importamos el componente ModalNewCampaign
import { ModalNewCampaign } from "./modals/ModalNewCampaign";
// Importamos componente con la animacion de carga de registros
import { LoadingAnimation } from '../../utils/components/loading/LoadingAnimation'

// ---------------------------------------------------------------------------------------

// Importamos la funcion campaignsListForProduct para conexion con API de campañas
import { campaignsListForProduct } from "./services/_CampaignsListApi";
// Importamos la funcion productInfoById para conexion con API de productos
import { productInfoById } from "../products/services/_ProductsListApi";

// ---------------------------------------------------------------------------------------

// Importamos funciones dateFormatter para formateo de fecha
import { dateFormatterTextualEn } from "../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de campañas que usaremos para este componente
import { WabData, Horario, Channel, Campaign } from "./interfaces/campaignsTypes";

import { DataTwl } from "../products/interfaces/productsTypes";
// ---------------------------------------------------------------------------------------

// Importamos funcion para vencimiento de sesion
import { sessionExpired } from "../../utils/functions/_Functions";
import { KTIcon } from "../../../_metronic/helpers";
import { DropdownExportCampaigns } from "../../../_metronic/partials/content/dropdown/DropdownExportCampaigns";

// Iniciamos funcion para construir componente Campaigns
export function Campaigns() {

  // console.log('Inicia renderizacion del componente de campañas');

  const initialTwlData: DataTwl = {
    twl_wab: "+14084191670",
    twl_lead_service: "MG319aca60a8cb539ea86798a8c5427594",
    twl_sellwab: "+5215568269661",
    twl_sell_service: "AC745a5dc60472fab84caff36051fb14dc",
    twl_sms: "+19105578437",
    twl_sms_accsid: "AC746dae333ec4c2c0f5a295c3da6ff473",
    twl_sms_accat: "ab4ff9c1f7d71648bacd07e754288dcf",
    twl_callid: "+525593378579",
    twl_call_accsid: "AC746dae333ec4c2c0f5a295c3da6ff473",
    twl_call_accat: "ab4ff9c1f7d71648bacd07e754288dcf",
    wab_status: false,
  };

  //Algunas variables desde el usercontext
  const { token, updateToken } = useContext(UserContext);
  const userToken = token;

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  //Variables para manejo del id y nombre de producto al que pertenece la campaña
  const [productName, setProductName] = useState<string>('');
  const [productId, setProductId] = useState<string>('');
  const [twlCredentials, setTwlCredentials] = useState<DataTwl>(initialTwlData)

  //Variables para el manejo de estado del filtro de estatus
  const [selectedFilter, setSelectedFilter] = useState<number>(1);

  //Variables para manejo de estado de la paginacion
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 6;

  //Variable para el uso de diccionario de lenduajes
  const intl = useIntl();

  //Variables para el manejo de estado del modal de creacion de campaña
  const [showModal, setShowModal] = useState<boolean>(false);

  //variables para el manejo de estado del arreglo con las campañas del producto
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);

  //Variables para el manejo del estado de la información de los products que se exportaran en csv y xlsx
  const [exportData, setExportData] = useState({})


  const initialFormNewManualCampaign = {
    name: "",
    connectionType: "",
    channelId: "",
    medium: "",
    mailTemplate: "",
    channelCrmId: "",
    crmId: "",
    startHour: "",
    endHour: "",
    shift: "",
    quality: true, 
    iscore: 5,
  }

  const [formDataManual, setFormDataManual] = useState(initialFormNewManualCampaign);

  const resetForm = () => {
    setFormDataManual(initialFormNewManualCampaign);
  }

  //Funcion para abrir el modal
  const openModal = () => {
    setShowModal(true);
  }

  //Funcion para cerrar el modal
  const closeModal = () => {
    setShowModal(false);
    resetForm();
  }

    //Función para definir el estado de la data a exportar al momento de dar click
    const handleSetExport = () => {
      setExportData(filterDataToExport)
      console.log('Se definió');
      console.log(filterDataToExport);
    }

  // console.log('lista de campañas: ', campaignsList);

  //Funcion para determinar el tamaño del arreglo de listas de marcas
  const checkCampaignsListLength = () => {
    //Si el arreglo tiene un tamaño igual a cero mandamos mensaje de campañas no existentes
    campaignsList.length === 0 && setInfoMessageLoading('No has creado ninguna campaña')
  }

  //Funcion para obtener el nombre del producto utilizando el id de la url
  const propertyChildProduct = async () => {

    const currentUrl = window.location.href;

    const hasProductParam = currentUrl.includes('product=');

    const productValue = new URLSearchParams(window.location.search).get('product');

    if (hasProductParam && productValue) {

      try {

        const { data: jsonData, code, msg } = await productInfoById(userToken, productValue);
        
        if ( code === 401 && msg === "Token no valido") {
          
          sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
          return;
          
        } else {
          
          const ProductNameReceived = jsonData.name;
          const ProductTwlCredentials = jsonData.data_twl;
          console.log('nombre de la marca: ', ProductNameReceived);
          setProductName(ProductNameReceived);
          setTwlCredentials(ProductTwlCredentials)

        }

      } catch(error) {

        console.error('Error al obtener la información del producto:', error);
        throw error;

      }

    } else {

      setProductName('generic');

    }

  }

  /*Funcion para conectarnos al servidor y obtener el listado de campañas 
  del producto con el id igual al de la url*/
  const getCampaignsRegisters = async () => {

    //Obtener la url actual
    const currentUrl = window.location.href;

    //Verificar si la url tiene el parámetro 'product'
    const hasProductParam = currentUrl.includes('product=');

    //Obtener el valor del parámetro 'product'
    const productValue = new URLSearchParams(window.location.search).get('product');

    if (hasProductParam && productValue) {

      setProductId(productValue);
      console.log('producto id en campañas', productId)

      try {

        propertyChildProduct();

        const { data: jsonData, code, msg } = await campaignsListForProduct(userToken, productValue);

        if ( code === 401 && msg === "Token no valido" ){

          sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
          setIsLoading(false)
          return;

        } else {

          const campaignsListReceived = jsonData.data;
          const userTokenNew = await jsonData.token;

          // console.log('actualiza token campaignsList (campaigns): ', userTokenNew)
          updateToken(userTokenNew);
  
          const campaignsCards = campaignsListReceived.map((campaign: Campaign) => {
  
            const campaignDateAddFormatted = dateFormatterTextualEn(campaign.date_add);
  
            const wabData: WabData = {
              // wab_templates: {
              //   wab_number: campaign.wab_data.wab_templates.wab_number,
              //   date_add: campaign.wab_data.wab_templates.date_add,
              // },
              wab_bot: campaign.wab_data.wab_bot,
              wab_qualitybot: campaign.wab_data.wab_qualitybot,
              // wab_messages: campaign.wab_data.wab_messages,
              leadwab_templates: {
                new_lead: campaign.wab_data.leadwab_templates.new_lead,
                welcome: campaign.wab_data.leadwab_templates.welcome,
                quality: campaign.wab_data.leadwab_templates.quality,
                reasigned: campaign.wab_data.leadwab_templates.reasigned,
              },

              sellerwab_templates: {
                new_lead: campaign.wab_data.sellerwab_templates.new_lead,
                welcome: campaign.wab_data.sellerwab_templates.welcome,
                quality: campaign.wab_data.sellerwab_templates.quality,
                reasigned: campaign.wab_data.sellerwab_templates.reasigned,
              },

              chatwab_templates: {
                new_lead: campaign.wab_data.chatwab_templates.new_lead,
                welcome: campaign.wab_data.chatwab_templates.welcome,
                quality: campaign.wab_data.chatwab_templates.quality,
                reasigned: campaign.wab_data.chatwab_templates.reasigned,
              }
            };
  
            const horario: Horario = {
              dias: campaign.horario.dias,
              start_hour: campaign.horario.start_hour,
              end_hour: campaign.horario.end_hour,
              shift: campaign.horario.shift,
            };
  
            const channel: Channel = {
              cam_channel: campaign.channel.cam_channel,
              type: campaign.channel.type,
              bu: campaign.channel.bu,
              channel_crm_id: campaign.channel.channel_crm_id,
            };
  
            return {
              wab_data: wabData,
              horario: horario,
              channel: channel,
              accessToken: campaign.accessToken,
              pro_id: campaign.pro_id,
              cli_id: campaign.cli_id,
              cam_id: campaign.cam_id,
              crm_id: campaign.crm_id,
              cam_status: campaign.cam_status,
              iscore: campaign.iscore,
              name: campaign.name,
              poc: campaign.poc,
              notify: campaign.notify,
              call: campaign.call,
              quality: campaign.quality,
              manual: campaign.manual,
              duplicate: campaign.duplicate,
              dupdays: campaign.dupdays,
              cam_role: campaign.cam_role,
              dcem: campaign.dcem,
              qr: campaign.qr,
              welcome_rec: campaign.welcome_rec,
              mail_template: campaign.mail_template,
              seller_mailtemplate: campaign.seller_mailtemplate,
              crm: campaign.crm,
              mailchimp: campaign.mailchimp,
              cam_menu: campaign.cam_menu,
              sms_message: campaign.sms_message,
              sms_template: campaign.sms_template,
              super_duper: campaign.super_duper,
              anti_flood: campaign.anti_flood,
              grade_options: campaign.grade_options,
              date_add: campaignDateAddFormatted,
              date_updated: campaign.date_updated,
              date_down: campaign.date_down,
              b2b_bot: campaign.b2b_bot,
            }
  
          });
  
          setCampaignsList(campaignsCards);
          //Ocultamos la animacion de carga
          setIsLoading(false)
          // console.log(campaignsCards);
          /* Ejecutamos la funcion para verificar  
          el tamaño del arreglo de marcas */
          checkCampaignsListLength()
        }

      }catch(error){
        setInfoMessageLoading('Ocurrio un error al intentar obtener las campañas')
        setIsLoading(false)
        // console.log('Error receivind campaigns: ', error);

      }

    } else {

    }

  }

  useEffect(() => {
    getCampaignsRegisters();
  }, [selectedFilter]);

  // const reRenderCampaigns = () => {
  //   getCampaignsRegisters();
  // }

  //Definimos una variable independiente de una función para ser la que
  //cambie constantemente y guarde la data de lo que se exportará
  let filterDataToExport = {};

  const getTotalEntries = () => {
    const filteredEntries = campaignsList
      .filter((campaign) => {
        if (selectedFilter === 0) return true;
        if (selectedFilter === 1) return campaign.cam_status === true;
        if (selectedFilter === 2) return campaign.cam_status === false;
        return false;
      });
      
      console.log('Contenido filtrado:', filteredEntries)
      filterDataToExport = filteredEntries; 
    
      return filteredEntries.length;

  };

  const renderCampaignCards = (getCampaignsRegisters: () => void) => {
    return campaignsList
      .filter((campaign) => {
        if (selectedFilter === 0) return true;
        if (selectedFilter === 1) return campaign.cam_status === true;
        if (selectedFilter === 2) return campaign.cam_status === false;
      })
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((campaign, index) => (
        <div key={index} className="col-sm-6 col-xl-4">
              <CardCampaigns
                productId={productId}
                twlCredentials={twlCredentials}
                wab_dataCampaign={[campaign.wab_data]}
                horarioCampaign={[campaign.horario]}
                channelCampaign={[campaign.channel]}
                ChannelCamChannel={campaign.channel.cam_channel}
                channelTypeCampaign={campaign.channel.type}
                channelBuCampaign={campaign.channel.bu}
                channelCrmIdCampaign={campaign.channel.channel_crm_id}
                accessTokenCampaign={campaign.accessToken}
                pro_idCampaign={campaign.pro_id}
                cli_idCampaign={campaign.cli_id}
                cam_idCampaign={campaign.cam_id}
                crm_idCampaign={campaign.crm_id}
                cam_statusCampaign={campaign.cam_status}
                iscoreCampaign={campaign.iscore}
                nameCampaign={campaign.name}
                // pocCampaign={campaign.poc}
                pocCampaign={'+525500000000'}
                notifyCampaign={campaign.notify}
                callCampaign={campaign.call}
                qualityCampaign={campaign.quality}
                manualCampaign={campaign.manual}
                duplicateCampaign={campaign.duplicate}
                dupdaysCampaign={campaign.dupdays}
                cam_roleCampaign={campaign.cam_role}
                dcemCampaign={campaign.dcem}
                qrCampaign={campaign.qr}
                welcome_recCampaign={campaign.welcome_rec}
                mail_templateCampaign={campaign.mail_template}
                seller_mailtemplateCampaign={campaign.seller_mailtemplate}
                crmCampaign={campaign.crm}
                mailchimpCampaign={campaign.mailchimp}
                cam_menuCampaign={campaign.cam_menu}
                sms_messageCampaign={campaign.sms_message}
                sms_templateCampaign={campaign.sms_template}
                super_duperCampaign={campaign.super_duper}
                anti_floodCampaign={campaign.anti_flood}
                grade_optionsCampaign={campaign.grade_options}
                date_addCampaign={campaign.date_add}
                date_updatedCampaign={campaign.date_updated}
                date_downCampaign={campaign.date_down}
                b2b_botCampaign={campaign.b2b_bot}
                getCampaignsRegisters={getCampaignsRegisters}
              /> 
        </div>
      ));
  };
 
  const renderPagination = () => {
    return (
      <ul className="pagination">
        {Array.from({ length: Math.ceil(getTotalEntries() / itemsPerPage) }).map((_, index) => {
          const page = index + 1;
          const totalPages = Math.ceil(getTotalEntries() / itemsPerPage);

          if(
            (page <= 3 && currentPage <= 5) ||
            (page > currentPage - 2 && page < currentPage + 2) ||
            (page > totalPages - 2 && currentPage <= totalPages - 4)
          ) {
            return (
              <li key={index} className={`page-item ${currentPage === page ? "active" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              </li>
            );
          }

          if (
            (page === 4 && currentPage > 5) ||
            (page === totalPages -1 && currentPage < totalPages -4)
          ) {
            return (
              <li key={index} className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            );
          }

          return null;

        })}
      </ul>
    );
  };

  const renderFilterDropdown = () => {
    return (
      <div className="w-150px me-5">
        <select
          name="status"
          data-control="select2"
          data-hide-search="true"
          className="form-select form-select-white form-select-sm"
          defaultValue="1"
          onChange={(e) => {
            setSelectedFilter(Number(e.target.value));
            setCurrentPage(1);
          }}
        >
          <option value="0">{intl.formatMessage({ id: "BRANDS.TITLE.FILTER_ALL" })}</option>
          <option value="1">{intl.formatMessage({ id: "BRANDS.TITLE.FILTER_ACTIVE" })}</option>
          <option value="2">{intl.formatMessage({ id: "BRANDS.TITLE.FILTER_UNACTIVE" })}</option>
        </select>
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>
                {intl.formatMessage({id: 'CAMPAIGNS.TITLE'})}
                <span className='fs-4 text-gray-500 fw-bold ms-1'> 
                  {productName !== 'generic' ? (
                    <> From Product: {productName} </>
                  ) : (
                    <> From All Client </>
                  )}
                </span>
              </h1>

              <div className='d-flex flex-wrap my-2'>
                {renderFilterDropdown()}
                <button 
                  type='button'
                  className='btn btn-primary btn-sm me-3' 
                  onClick={openModal}
                >
                  {intl.formatMessage({id: 'CAMPAIGNS.BUTTON.NEW_CAMPAIGN'})}
                </button>

                <button
                  type='button'
                  className='btn btn-primary d-flex align-items-center btn-sm me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={handleSetExport}
                >
                  <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                  <span>Exportar datos</span>
                </button>
                <DropdownExportCampaigns dataToExport={exportData} title={'Campaigns'} />


                <Modal
                  show={showModal}
                  onHide={closeModal}
                  scrollable
                  className="modal-lg"
                >
                  <Modal.Header closeButton onClick={resetForm}>
                    <Modal.Title>
                      <h1>{intl.formatMessage({id: 'CAMPAIGNS.MODAL_NEW.TITLE'})}</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ModalNewCampaign 
                      closeModal={closeModal}
                      productId={productId} 
                      twlCredentials={twlCredentials}
                      setCurrentPage={setCurrentPage} 
                      formDataManual={formDataManual}
                      setFormDataManual={setFormDataManual}
                      getCampaignsRegisters={getCampaignsRegisters}/>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                        className="btn btn-sm btn-secondary"
                        type="button"
                        onClick={() => {
                          closeModal();
                          // resetform();
                        }}
                      >
                        {intl.formatMessage({id: 'CAMPAIGNS.MODAL_NEW.RESET_BUTTON'})}
                    </button>
                  </Modal.Footer>
                </Modal>

              </div>
            </div>

            <div className='row g-6 g-xl-9'>
              {/* Validaciones para determinar que elementos mostraremos en la pantalla */}

              {/* Si isLoading es igual a 'true' lo mostramos */}
              {isLoading && <LoadingAnimation alignment='center' label='Obteniendo campañas...' size='lg' />}

              {/* Si isLoading es diferente de 'true' y el tamaño de campaignsList
              es mayor a 0 mostramos el componente de tarjetas de campañas */}
              {!isLoading && campaignsList.length > 0 && renderCampaignCards(getCampaignsRegisters)}

              {/* Si isLoading es diferente de 'true' y el tamaño de campaignsList
              es igual a 0 mostramos el mensaje informativo */}
              {!isLoading && campaignsList.length === 0 && <p>{infoMessageLoading}</p>}
            
            </div>
            <div className='d-flex flex-stack flex-wrap pt-10'>
              <div className='fs-6 fw-bold text-gray-700'>
                {`Showing ${((currentPage - 1) * itemsPerPage) + 1} to ${Math.min(
                  currentPage * itemsPerPage,
                  getTotalEntries()
                )} of ${getTotalEntries()} entries`}
              </div>
              {renderPagination()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}